<template>
  <g clip-path="url(#clip02)">
    <path
      d="M3.7161 10.243L2.87143 15.421C2.8041 15.8323 3.24343 16.141 3.60677 15.939L8.0001 13.511L12.3934 15.9396C12.7534 16.1396 13.1968 15.837 13.1288 15.4216L12.2841 10.2437L15.8574 6.581C16.1408 6.291 15.9781 5.799 15.5754 5.73767L10.6568 4.98634L8.4521 0.290343C8.28743 -0.0603228 7.7121 -0.0603228 7.54743 0.290343L5.34343 4.98567L0.424766 5.737C0.0207661 5.799 -0.140567 6.29033 0.142766 6.58033L3.7161 10.243Z"
    />
  </g>
  <defs>
    <clipPath id="clip02">
      <rect width="16" height="16" fill="white" />
    </clipPath>
  </defs>
</template>
